import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon
import LogoImage from '../img/360Geoinfo_Logo_PNG.png';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import './NavBar.css';

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [clickEvent, setClickEvent] = useState(null);
  const [path, setPath] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState('#000'); // Default background color

  useEffect(() => {
    // Update the CSS variable whenever backgroundColor changes
    document.documentElement.style.setProperty('--background-color', backgroundColor);
  }, [backgroundColor]);

  const handleDrawerButtonClick = (event) => {
    setClickEvent(event);
    setDrawerOpen(true);

    // Create ripple effect
    const ripple = document.createElement('span');
    const rect = event.currentTarget.getBoundingClientRect();
    const size = Math.max(rect.width, rect.height);
    const x = event.clientX - rect.left - size / 2;
    const y = event.clientY - rect.top - size / 2;

    ripple.style.width = ripple.style.height = `${size}px`;
    ripple.style.left = `${x}px`;
    ripple.style.top = `${y}px`;
    ripple.classList.add('ripple');

    event.currentTarget.appendChild(ripple);

    // Remove ripple effect after animation
    setTimeout(() => {
      ripple.remove();
    }, 600);
  };

  const handleNavClick = (event, newPath, color) => {
    setClickEvent(event);
    setPath(newPath);
    setDrawerOpen(false); // Close the drawer after clicking a link
    setBackgroundColor(color); // Set background color based on the clicked link

    // Delay the navigation to ensure the circle transition completes
    setTimeout(() => {
      setClickEvent(null);
      setPath(null);
    }, 500); // Duration should match the CircleTransition animation duration
  };

  const handleExitButtonClick = () => {
    setDrawerOpen(false); // Close the drawer when exit button is clicked
  };

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <div className="navbar-brand">
          <Link to="/">
            <img src={LogoImage} alt="YourLogo" className="logo-image" />
          </Link>
        </div>
        <div className="navbar-text">
        <Link to="/geospatial" className="navbar-link">Geospatial</Link> .  
        <Link to="/gis" className="navbar-link"> GIS</Link> .
        <Link to="/geoscience" className="navbar-link">Geoscience</Link> .  
        <Link to="/geoict" className="navbar-link"> Geo-ICT</Link>
        </div>
        <motion.button
          className="drawer-button"
          onClick={handleDrawerButtonClick}
          whileTap={{ scale: 0.9 }} // Scale down on click
          transition={{ duration: 0.2 }} // Duration of the scaling animation
        >
          <MenuIcon />
        </motion.button>
        <AnimatePresence>
          {drawerOpen && (
            <motion.div
              className="full-screen-menu"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              <button className="exit-button" onClick={handleExitButtonClick}>
                <CloseIcon className="close-icon" /> {/* Use CloseIcon */}
              </button>
              <ul>
                <li>
                  <Link to="/about" onClick={(e) => handleNavClick(e, '/about', '#627965')}>About Us</Link>
                </li>
                <li>
                  <Link to="/services" onClick={(e) => handleNavClick(e, '/services', '#D6B892')}>Our Services</Link>
                </li>
                <li>
                  <Link to="/contact" onClick={(e) => handleNavClick(e, '/contact', '#3D3528')}>Contact Us</Link>
                </li>
              </ul>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className="navbar-right">
        <Link to="/contactcontent" className="contact-link">
          <div className="contact-box">
            <p className="contact-text-top-right">Contact Us</p>
            <div className="logo-container">
              <div className="logo-background">
                <a href="https://wa.me/12345678901" target="_blank" rel="noopener noreferrer">
                  <WhatsAppIcon className="whatsapp-icon" />
                </a>
              </div>
            </div>
          </div>
        </Link>
      </div>
      {/* {clickEvent && path && <CircleTransition clickEvent={clickEvent} path={path} />} */}
    </nav>
  );
};

export default Navbar;
