import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import emailjs from 'emailjs-com'; // Import emailjs
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import './ContactContent.css';
import LogoImage from '../img/360Geoinfo_Logo_PNG.png';
import contactImage from '../img/Rectangle 43.png'; // Update with the correct path to your image

const tabsConfig = [
  { name: 'about', label: 'About', color: '#627965' },
  { name: 'services', label: 'Services', color: '#D6B892' },
  { name: 'contact', label: 'Contact', color: '#3D3528' }
];

const ContactContent = () => {
    const [clickEvent, setClickEvent] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState(tabsConfig[0].color);
    const [path, setPath] = useState(null);
    const [formStatus, setFormStatus] = useState(null); // null, 'success', or 'error'
    const [isSubmitting, setIsSubmitting] = useState(false); // Loading state
    
    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSubmitting(true); // Set loading state
        
        const formData = {
            name: event.target.name.value,
            email: event.target.email.value,
            message: event.target.message.value,
        };
    
        // Send email using EmailJS
        emailjs.send(
            'service_0kimsd2',        // Replace with your EmailJS Service ID
            'template_yhwf6i4',       // Replace with your EmailJS Template ID
            formData,                 // Data to send
            'rknwn2b-Zl6kdty20'       // Replace with your EmailJS User ID (public key)
        )
        .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            setFormStatus('success');
        })
        .catch((error) => {
            console.error('FAILED...', error);
            setFormStatus('error');
        })
        .finally(() => {
            setIsSubmitting(false); // Reset loading state after submission
        });
    };

    const handleGoBackToForm = () => {
      setFormStatus(null); // Reset form status to null to show the form again
    };

    const handleDrawerButtonClick = (event) => {
      setClickEvent(event);
      setDrawerOpen(true);
  
      // Create ripple effect
      const ripple = document.createElement('span');
      const rect = event.currentTarget.getBoundingClientRect();
      const size = Math.max(rect.width, rect.height);
      const x = event.clientX - rect.left - size / 2;
      const y = event.clientY - rect.top - size / 2;
  
      ripple.style.width = ripple.style.height = `${size}px`;
      ripple.style.left = `${x}px`;
      ripple.style.top = `${y}px`;
      ripple.classList.add('ripple');
  
      event.currentTarget.appendChild(ripple);
  
      // Remove ripple effect after animation
      setTimeout(() => {
        ripple.remove();
      }, 600);
    };
  
    const handleExitButtonClick = () => {
      setDrawerOpen(false); // Close the drawer when exit button is clicked
    };
  
    const handleNavClick = (event, newPath, color) => {
      setClickEvent(event);
      setPath(newPath);
      setDrawerOpen(false); // Close the drawer after clicking a link
      setBackgroundColor(color); // Set background color based on the clicked link
  
      // Delay the navigation to ensure the circle transition completes
      setTimeout(() => {
        setClickEvent(null);
        setPath(null);
      }, 500); // Duration should match the CircleTransition animation duration
    };

    return (
        <div className="contact-background">
            <div className="logo-navigation-contact-content">
                <Link to="/">
                    <img src={LogoImage} alt="YourLogo" className="logo-image-contact" />
                </Link>
                    <div className="navbar-text">
                        <Link to="/geospatial" className="navbar-link">Geospatial</Link> .  
                        <Link to="/gis" className="navbar-link"> GIS</Link> .
                        <Link to="/geoscience" className="navbar-link">Geoscience</Link> .  
                        <Link to="/geoict" className="navbar-link"> Geo-ICT</Link>
                    </div>
                <motion.button
                className="other-drawer-button-contact-content"
                onClick={handleDrawerButtonClick}
                whileTap={{ scale: 0.9 }} // Scale down on click
                transition={{ duration: 0.2 }} // Duration of the scaling animation
                >
                <MenuIcon />
                </motion.button>
                <AnimatePresence>
                {drawerOpen && (
                    <motion.div
                    className="other-full-screen-menu-contact-content"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    >
                    <button className="other-exit-button-contact-content" onClick={handleExitButtonClick}>
                        <CloseIcon className="other-close-icon-contact-content" /> {/* Use CloseIcon */}
                    </button>
                    <ul>
                        <li>
                        <Link to="/about" onClick={(e) => handleNavClick(e, '/about', '#627965')}>About Us</Link>
                        </li>
                        <li>
                        <Link to="/services" onClick={(e) => handleNavClick(e, '/services', '#D6B892')}>Our Services</Link>
                        </li>
                        <li>
                        <Link to="/contact" onClick={(e) => handleNavClick(e, '/contact', '#3D3528')}>Contact Us</Link>
                        </li>
                    </ul>
                    </motion.div>
                )}
                </AnimatePresence>
                <div className="other-navbar-right-contact-content">
                    <Link to="/contactcontent" className="other-contact-link-contact-content">
                    <div className="other-contact-box-contact-content">
                        <p className="other-contact-text-top-right-contact-content">Contact Us</p>
                        <div className="other-logo-container-contact-content">
                        <div className="other-logo-background-contact-content">
                            <a href="https://wa.me/12345678901" target="_blank" rel="noopener noreferrer">
                            <WhatsAppIcon className="other-whatsapp-icon-contact-content" />
                            </a>
                        </div>
                        </div>
                    </div>
                    </Link>
                </div>
            </div>
            <div className="contact-content-container">
                <div className="contact-form">
                    <h2>CONTACT</h2>
                    <h2 className="contact-phone">T: +673 2331 360</h2>
                    <div className="contact-details">
                        <div className="address-section">
                            <h3>ADDRESS</h3>
                            <p>
                                Unit J64, 2nd Floor<br />
                                Block J, Sunway Centre<br />
                                Kg Sg Tilong, Brunei
                            </p>
                        </div>
                        <div className="social-section">
                            <h3>SOCIAL</h3>
                            <p>
                                Instagram<br />
                                LinkedIn
                            </p>
                        </div>
                    </div>

                    {formStatus === null && (
                        <form onSubmit={handleSubmit}>
                            <h2>CONTACT FORM</h2>
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <input type="text" id="name" name="name" placeholder="Enter your name" required />
                            </div>

                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input type="email" id="email" name="email" placeholder="Enter your email" required />
                            </div>

                            <div className="form-group">
                                <label htmlFor="message">Message</label>
                                <textarea id="message" name="message" rows="1" placeholder="Enter your message" required></textarea>
                            </div>
                            <div className="form-button-wrapper">
                                <motion.button
                                    className="form-button-container"
                                    type="submit"
                                    whileTap={{ scale: 0.9 }}
                                    disabled={isSubmitting} // Disable button during submission
                                    transition={{ duration: 0.2 }}
                                    initial={{ opacity: 1 }}
                                    animate={{ opacity: isSubmitting ? 0.7 : 1 }} // Dim button during loading
                                >
                                    {isSubmitting ? (
                                        <>
                                            Submitting...
                                            <span className="spinner"></span> {/* Show spinner */}
                                        </>
                                    ) : (
                                        'Submit'
                                    )}
                                </motion.button>
                            </div>
                        </form>
                    )}

                    {formStatus === 'success' && (
                        <div className="form-status-message success-message">
                            <p>Thank you! The 360 GeoInfo Team will get back to you shortly.</p>
                            <button onClick={handleGoBackToForm} className="go-back-button">Go Back to Form</button>
                        </div>
                    )}

                    {formStatus === 'error' && (
                        <div className="form-status-message error-message">
                            <p>There was an error sending your message. Please try again.</p>
                            <button onClick={handleGoBackToForm} className="go-back-button">Go Back to Form</button>
                        </div>
                    )}
                </div>
                <div className="contact-image-content">
                    <img src={contactImage} alt="Contact Us" />
                </div>
            </div>
        </div>
    );
};

export default ContactContent;