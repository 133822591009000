import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import './ContactPage.css'; // Ensure this CSS file includes necessary styling
import contactImage from '../img/ContactProfile.png'; // Import your contact image here
import arrowImg from '../img/arrow.png'; // Import your arrow image
import PageTransitionAntiClockwise from '../PageTransitionAntiClockWise'; // Ensure the import matches your transition component
import Navbar from '../Navigation/Navbar';

// Define the tabsConfig array
const tabsConfig = [
  { name: 'about', label: 'ABOUT', color: '#627965' },
  { name: 'services', label: 'SERVICES', color: '#D6B892' },
  { name: 'contact', label: 'CONTACT', color: '#3D3528' }
];

const wordingVariants = {
  initial: { opacity: 0, y: -20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 20 },
  transition: { duration: 0.5 }
};

const imageVariants = {
  initial: { scale: 0.9, opacity: 0 },
  animate: { scale: 1, opacity: 1 },
  exit: { scale: 1.1, opacity: 0 },
  transition: { duration: 0.6 }
};


const ContactPage = () => {
  const [activeTab, setActiveTab] = useState('contact');
  const navigate = useNavigate();
  const [backgroundColor, setBackgroundColor] = useState(tabsConfig[2].color);

  useEffect(() => {
    document.body.classList.add('contact-body');

    // Clean up by removing the class when the component is unmounted
    return () => {
      document.body.classList.remove('contact-body');
    };
  }, []);

  useEffect(() => {    
    document.documentElement.style.setProperty('--background-color', backgroundColor);
  }, [backgroundColor]);

  const handleTabClick = (tab) => {
    const tabConfig = tabsConfig.find(t => t.name === tab);
    if (tabConfig) {
      document.documentElement.style.setProperty('--background-color', tabConfig.color);
      document.documentElement.style.setProperty('--background-image', `url('/src/components/img/AboutUsBlur.jpg')`);
    }
  
    setActiveTab(tab);
    navigate(`/${tab}`);
  };  

  return (
    <motion.div 
       className="contact-page" 
       transition={{ duration: 0.5 }}
       >
    <div className="navbar">
      <Navbar />
    </div>
      <div className="contact-container">
        <div className="contact-content">
          <motion.div
            className="contact-us-text"
            variants={wordingVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={wordingVariants.transition}
          >
            <div className="contact-header">
              <p className="contact-subheading">03. CONTACT</p>
              <h1 className="contact-title">
                <span className="contact-text-white">Join the flow, </span>
                <br />
                <span className="contact-text-highlight">contact us</span>
              </h1>
              {/* Delete the button */}
              <button className="contact-button" onClick={() => navigate('/contactcontent')}>
                <span className="learn-more-text">Learn More</span>
                <img src={arrowImg} alt="Arrow" />
              </button>
            </div>
            <div className="mini-nav-tabs">
              {tabsConfig.map((tab) => (
                <a
                  key={tab.name}
                  onClick={() => handleTabClick(tab.name)}
                  className={`tab-link-contact ${activeTab === tab.name ? 'active' : ''}`}
                  href="#"
                >
                  {tab.label}
                </a>
              ))}
            </div>
            <AnimatePresence>
              {tabsConfig.map((tab) => (
                activeTab === tab.name && (
                  <PageTransitionAntiClockwise key={tab.name}>
                    <motion.div
                      variants={wordingVariants}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                      transition={wordingVariants.transition}
                    >
                      {/* Content for the tab can be added here */}
                    </motion.div>
                  </PageTransitionAntiClockwise>
                )
              ))}
            </AnimatePresence>
          </motion.div>
          <motion.div
            className="contact-image-container"
            initial="initial"
            animate="animate"
            variants={imageVariants} 
            exit="exit"
          >
            <img src={contactImage} alt="Contact Us" className="contact-image"  />
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default ContactPage;
