import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../Navigation/Navbar';
import './ServiceContent.css'; // Import the CSS file
import geospatial from "../img/Ellipse 7.png";
import gis from "../img/Ellipse 8.png";
import geoict from "../img/Ellipse 9.png";
import geoscience from "../img/AboutUs Profile.png";

const tabsConfig = [
  { name: 'about', label: 'About', color: '#627965' },
  { name: 'services', label: 'Services', color: '#D6B892' },
  { name: 'contact', label: 'Contact', color: '#3D3528' }
];

const ServiceContent = () => {
  const [clickEvent, setClickEvent] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [path, setPath] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState('#000'); // Default background color

  useEffect(() => {
    // Update the CSS variable whenever backgroundColor changes
    document.documentElement.style.setProperty('--background-color', backgroundColor);
  }, [backgroundColor]);

  const handleDrawerButtonClick = (event) => {
    setClickEvent(event);
    setDrawerOpen(true);

    // Create ripple effect
    const ripple = document.createElement('span');
    const rect = event.currentTarget.getBoundingClientRect();
    const size = Math.max(rect.width, rect.height);
    const x = event.clientX - rect.left - size / 2;
    const y = event.clientY - rect.top - size / 2;

    ripple.style.width = ripple.style.height = `${size}px`;
    ripple.style.left = `${x}px`;
    ripple.style.top = `${y}px`;
    ripple.classList.add('ripple');

    event.currentTarget.appendChild(ripple);

    // Remove ripple effect after animation
    setTimeout(() => {
      ripple.remove();
    }, 600);
  };

  const handleNavClick = (event, newPath, color) => {
    setClickEvent(event);
    setPath(newPath);
    setDrawerOpen(false); // Close the drawer after clicking a link
    setBackgroundColor(color); // Set background color based on the clicked link

    // Delay the navigation to ensure the circle transition completes
    setTimeout(() => {
      setClickEvent(null);
      setPath(null);
    }, 500); // Duration should match the CircleTransition animation duration
  };

  const handleExitButtonClick = () => {
    setDrawerOpen(false); // Close the drawer when exit button is clicked
  };

  return (
    <div className="service-content-page">
      <div className="navbar">
        <Navbar />
      </div>
        <div className="phone-virtual">
          <h1 className="service-title-content">SERVICES</h1>
          <div className="service-list">
            <div className="service-item">
              <Link to="/geospatial" className="service-link">
                Geospatial
              </Link>
              <img src={geospatial} alt="Geospatial" />
            </div>
            <div className="service-item">
              <Link to="/gis" className="service-link">
                Geographic Information System (GIS)
              </Link>
              <img src={gis} alt="GIS" />
            </div>
            <div className="service-item">
              <Link to="/geoict" className="service-link">
                Geo-ICT
              </Link>
              <img src={geoict} alt="Geo-ICT" />
            </div>
            <div className="service-item">
              <Link to="/geoscience" className="service-link">
                Geoscience
              </Link>
              <img src={geoscience} alt="Geoscience" />
            </div>
          </div>
        </div>
    </div>
  );
};

export default ServiceContent;
