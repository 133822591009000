import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Slider from 'react-slick';
import gnss from "../../img/ORTHO_BUNUT_MOSQUE.png";
import seabed from "../../img/ORTHO_BLNG 1.png";
import tls from "../../img/DSM 1.png";
import ariel from "../../img/WhatsApp Image 2024-08-06 at 13.07.00 1.png";
import sliderImg6 from "../../img/GIS/3D City Data Modelling.png";  // Separate images for slider
import sliderImg2 from "../../img/GIS/Customised Map Production and Digital Cartography.png"; // Add more images for the slider
import sliderImg4 from "../../img/GIS/Georeferencing and Topology Creating.png";  // Separate images for slider
import sliderImg5 from "../../img/GIS/Orthophoto Processing.png";  // Separate images for slider
import sliderImg3 from "../../img/GIS/Remote Sensing and Photogrammetry.png"; // Add more images for the slider
import sliderImg1 from "../../img/GIS/Spatial Data Creation and Management.png"; // Add more images for the slider
import LogoImage from '../../img/360Geoinfo_Logo_PNG.png';
import './GIS.css';

const tabsConfig = [
  { name: 'about', label: 'About', color: '#627965' },
  { name: 'services', label: 'Services', color: '#D6B892' },
  { name: 'contact', label: 'Contact', color: '#3D3528' }
];

const GisPage = () => {
    const [clickEvent, setClickEvent] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState(tabsConfig[0].color);
    const [path, setPath] = useState(null);
    const largeImageRef = useRef(null);
    const smallImagesRef = useRef(null);
    const textContentRef = useRef(null);
    const titleRef = useRef(null);

    const slideContentsGis = [
        "Through the use of specialized softwares, we process remote sensing and photogrammetry data, delivering precise geospatial information for complex analysis.",
        "We employ advanced georeferencing techniques and topology creation ensuring spatial data aligns accurately with real-world coordinates for reliable geographic analysis.",
        "We offer specialized training and consultation on leading GIS platforms like ArcGIS and QGIS, helping clients enhance their skills and effectively implement GIS technologies in their projects.",
        "Leveraging GIS software, we create detailed 3D city models, providing comprehensive visualization tools for urban planning, infrastructure development, and environmental impact assessments.",
        "We provide expert spatial data creation and management, using GIS softwares  to build and maintain accurate geospatial databases, crucial for informed decision-making.",
        "Utilizing tools like MapInfo and ArcGIS, we produce customized maps and digital cartography tailored to specific needs, supporting everything from urban planning to environmental management."
    ];

    const slideTitlesGis = [
        "Remote Sensing and Photogram-metry",
        "Georeferencing and Topology Creating",
        "Training and Consultation",
        "3D City Data Modelling",
        "Spatial Data Creation and Management",
        "Customised Map Production and Digital Cartography"
    ];

    const sliderImages = [sliderImg1, sliderImg2, sliderImg3, sliderImg4, sliderImg5, sliderImg6]; // Images for the slider

    const [activeSlide, setActiveSlide] = useState(0);

    useEffect(() => {
        const options = {
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animate');
                }
            });
        }, options);

        observer.observe(largeImageRef.current);
        observer.observe(smallImagesRef.current);
        observer.observe(textContentRef.current);
        observer.observe(titleRef.current);

        return () => observer.disconnect();
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        afterChange: (current) => {
            setActiveSlide(current);
        },
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };  

    const handleDrawerButtonClick = (event) => {
      setClickEvent(event);
      setDrawerOpen(true);
  
      // Create ripple effect
      const ripple = document.createElement('span');
      const rect = event.currentTarget.getBoundingClientRect();
      const size = Math.max(rect.width, rect.height);
      const x = event.clientX - rect.left - size / 2;
      const y = event.clientY - rect.top - size / 2;
  
      ripple.style.width = ripple.style.height = `${size}px`;
      ripple.style.left = `${x}px`;
      ripple.style.top = `${y}px`;
      ripple.classList.add('ripple');
  
      event.currentTarget.appendChild(ripple);
  
      // Remove ripple effect after animation
      setTimeout(() => {
        ripple.remove();
      }, 600);
    };
  
    const handleExitButtonClick = () => {
      setDrawerOpen(false); // Close the drawer when exit button is clicked
    };
  
    const handleNavClick = (event, newPath, color) => {
      setClickEvent(event);
      setPath(newPath);
      setDrawerOpen(false); // Close the drawer after clicking a link
      setBackgroundColor(color); // Set background color based on the clicked link
  
      // Delay the navigation to ensure the circle transition completes
      setTimeout(() => {
        setClickEvent(null);
        setPath(null);
      }, 500); // Duration should match the CircleTransition animation duration
    };

    return (
        <div className="gis-container">
            <div className="logo-navigation">
                <Link to="/">
                    <img src={LogoImage} alt="YourLogo" className="logo-image" />
                </Link>
                    <div className="navbar-text">
                        <Link to="/geospatial" className="navbar-link">Geospatial</Link> .  
                        <Link to="/gis" className="navbar-link"> GIS</Link> .
                        <Link to="/geoscience" className="navbar-link">Geoscience</Link> .  
                        <Link to="/geoict" className="navbar-link"> Geo-ICT</Link>
                    </div>
                <motion.button
                className="other-drawer-button-GIS-content"
                onClick={handleDrawerButtonClick}
                whileTap={{ scale: 0.9 }} // Scale down on click
                transition={{ duration: 0.2 }} // Duration of the scaling animation
                >
                <MenuIcon />
                </motion.button>
                <AnimatePresence>
                {drawerOpen && (
                    <motion.div
                    className="other-full-screen-menu-GIS-content"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    >
                    <button className="other-exit-button-GIS-content" onClick={handleExitButtonClick}>
                        <CloseIcon className="other-close-icon-GIS-content" /> {/* Use CloseIcon */}
                    </button>
                    <ul>
                        <li>
                        <Link to="/about" onClick={(e) => handleNavClick(e, '/about', '#627965')}>About Us</Link>
                        </li>
                        <li>
                        <Link to="/services" onClick={(e) => handleNavClick(e, '/services', '#D6B892')}>Our Services</Link>
                        </li>
                        <li>
                        <Link to="/contact" onClick={(e) => handleNavClick(e, '/contact', '#3D3528')}>Contact Us</Link>
                        </li>
                    </ul>
                    </motion.div>
                )}
                </AnimatePresence>
                <div className="other-navbar-right-GIS-content">
                    <Link to="/contactcontent" className="other-contact-link-GIS-content">
                    <div className="other-contact-box-GIS-content">
                        <p className="other-contact-text-top-right-GIS-content">Contact Us</p>
                        <div className="other-logo-container-GIS-content">
                        <div className="other-logo-background-GIS-content">
                            <a href="https://wa.me/12345678901" target="_blank" rel="noopener noreferrer">
                            <WhatsAppIcon className="other-whatsapp-icon-GIS-content" />
                            </a>
                        </div>
                        </div>
                    </div>
                    </Link>
                </div>
            </div>
            <div className="gis-phone-view">
                <div className="gis-image-row">
                    {/* Large Image */}
                    <div className="gis-image-large" ref={largeImageRef}>
                        <img src={gnss} alt="Large GIS" />
                    </div>

                    {/* Small Images and Title */}
                    <div className="gis-small-images-container" ref={smallImagesRef}>
                        <div className="gis-small-images">
                            <img src={seabed} alt="Small GIS 1" className="gis-image-small" />
                            <img src={tls} alt="Small GIS 2" className="gis-image-small" />
                            <img src={ariel} alt="Small GIS 3" className="gis-image-small" />
                        </div>
                    </div>
                </div>

                {/* Carousel */}
                <div className="gis-carousel-container">
                        {/* Title Below Small Images */}
                        <div className="gis-title" ref={titleRef}>
                            <h2>Geographic Information System (GIS)</h2>
                        </div>
                    <Slider {...settings}>
                        {sliderImages.map((image, index) => (
                            <div key={index} className={`gis-carousel-card ${activeSlide === index ? 'active' : ''}`}>
                                <div className={`gis-carousel-card-content ${activeSlide === index ? 'active' : ''}`}>
                                    <div className="gis-carousel-card-image">
                                        <div className="gis-carousel-card-text">
                                            <div style={{ textAlign: 'center', color: 'white', fontSize: 30, fontFamily: 'Avenir Next Cyr', fontWeight: '450', wordWrap: 'break-word' }}>
                                                {slideTitlesGis[index]}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>

                <div className="gis-text-content" ref={textContentRef}>
                    <p>{slideContentsGis[activeSlide]}</p>
                </div>
            </div>
        </div>
    );
};

export default GisPage;
