import React from 'react';
import drone from './img/drone.gif';
import logo from './img/360Geoinfo_Logo_PNG.png';  // Assuming the logo is stored in this location

const Loader = () => {
  return (
    <div style={styles.container}>
      <img src={logo} alt="Logo" style={styles.logo} />  {/* Added Logo */}
      <div style={styles.droneWrapper}>
        <img src={drone} alt="drone" style={styles.drone} />
      </div>
      <h2 style={styles.loadingText}>
        Loading
        <span style={styles.dot}>.</span>
        <span style={{ ...styles.dot, animationDelay: '0.2s' }}>.</span>
        <span style={{ ...styles.dot, animationDelay: '0.4s' }}>.</span>
      </h2>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#D6B892',
  },
  logo: {
    width: '300px',
    height: '300px',
    marginBottom: '20px',  // Space between the logo and the drone
    animation: 'fade-in 2s ease-in-out',  // Fade-in animation for the logo
  },
  droneWrapper: {
    width: '150px',
    height: '150px',
    animation: 'fly-in 4s ease-in-out',  // Drone animation changed to 7 seconds
  },
  drone: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  loadingText: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginTop: '20px',
  },
  dot: {
    display: 'inline-block',
    fontSize: '24px',
    animation: 'jump 1s infinite',  // Jump animation for dots
  },
};

// CSS for the drone, jumping dots, and fade-in effect
const css = `
@keyframes fly-in {
  0% {
    transform: translateX(-100vw);
  }
  50% {
    transform: translateX(0) rotate(360deg);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes jump {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}`;

// Append the keyframes to the document head
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = css;
document.head.appendChild(styleSheet);

export default Loader;
