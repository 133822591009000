// src/App.jsx
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Navbar from './components/Navigation/Navbar';
import AboutPage from './components/AboutPage/AboutPage';
import ServicesPage from './components/ServicePage/ServicesPage';
import ContactPage from './components/ContactPage/ContactPage';
import AboutUs from './components/AboutPage/AboutUs';
import ServiceContent from './components/ServicePage/ServiceContent';
import GisPage from './components/ServicePage/GIS/GIS';
import GeoICTPage from './components/ServicePage/GeoICT/GeoICT';
import GeoSciencePage from './components/ServicePage/GeoScience/GeoScience';
import GeoSpatialPage from './components/ServicePage/GeoSpatial/GeoSpatial';
import ContactContent from './components/ContactPage/ContactContent';
import PageTransition from './components/PageTransitionAntiClockWise';
import Loader from './components/Loading'; // Import Loader
// import Video from './components/vid/AboutUs.mp4';

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<PageTransition><AboutPage /></PageTransition>} />
        <Route path="/about" element={<PageTransition><AboutPage /></PageTransition>} />
        <Route path="/services" element={<PageTransition><ServicesPage /></PageTransition>} />
        <Route path="/contact" element={<PageTransition><ContactPage /></PageTransition>} />
        <Route path="/aboutus" element={<PageTransition><AboutUs /></PageTransition>} />
        <Route path="/servicecontent" element={<PageTransition><ServiceContent /></PageTransition>} />
        <Route path="/gis" element={<PageTransition><GisPage /></PageTransition>} />
        <Route path="/geospatial" element={<PageTransition><GeoSpatialPage /></PageTransition>} />
        <Route path="/geoscience" element={<PageTransition><GeoSciencePage /></PageTransition>} />
        <Route path="/geoict" element={<PageTransition><GeoICTPage /></PageTransition>} />
        <Route path="/contactcontent" element={<PageTransition><ContactContent /></PageTransition>} />
      </Routes>
    </AnimatePresence>
  );
};

const App = () => {
  const [loading, setLoading] = useState(true); // Track loading state

  useEffect(() => {
    // Simulate loading delay (you can adjust or remove this based on your use case)
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Show loader for 2 seconds

    return () => clearTimeout(timer); // Cleanup
  }, []);

  return (
    <Router>
      {loading ? ( 
        <Loader /> // Show Loader when loading
      ) : (
        <>
          {/* <Video /> */}
          <AnimatedRoutes />
        </>
      )}
    </Router>
  );
};

export default App;
