import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import './AboutPage.css';
import arrowImg from '../img/arrow.png'; // Import your arrow image
import img2 from '../img/AboutUs Profile.png';
import PageTransition from '../PageTransitionAntiClockWise';
import Navbar from '../Navigation/Navbar';

const tabsConfig = [
  { name: 'about', label: 'ABOUT', color: '#627965', path: '/about' },
  { name: 'services', label: 'SERVICES', color: '#D6B892', path: '/services' },
  { name: 'contact', label: 'CONTACT', color: '#3D3528', path: '/contact' }
];

const wordingVariants = {
  initial: { opacity: 0, y: -20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 20 },
  transition: { duration: 0.5 }
};

const imageVariants = {
  initial: { scale: 0.9, opacity: 0 },
  animate: { scale: 1, opacity: 1 },
  exit: { scale: 1.1, opacity: 0 },
  transition: { duration: 0.6 }
};

const AboutPage = () => {
  const [activeTab, setActiveTab] = useState(tabsConfig[0].name);
  const navigate = useNavigate();
  const [backgroundColor, setBackgroundColor] = useState(tabsConfig[0].color);

  useEffect(() => {
    document.body.classList.add('about-body');

    return () => {
      document.body.classList.remove('about-body');
    };
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty('--background-color', backgroundColor);
  }, [backgroundColor]);

  const handleTabClick = (tab) => {
    const tabConfig = tabsConfig.find(t => t.name === tab);
    if (tabConfig) {
      setBackgroundColor(tabConfig.color);
      document.documentElement.style.setProperty('--background-image', `url('/src/components/img/AboutUsBlur.jpg')`);
      setActiveTab(tab);
      navigate(tabConfig.path); // Ensure path is correctly used here
    }
  };

  return (
    <motion.div
      className="about-page"
      transition={{ duration: 0.5 }}
    >
    <div className="navbar">
      <Navbar />
    </div>
      <div className="about-container">
        <div className="about-content">
          <motion.div
            className="about-text"
            variants={wordingVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={wordingVariants.transition}
          >
            <div className="about-header">
              <p className="subheading">01. ABOUT</p>
              <h1 className="about-title">
                <span className="about-text-white">The key to </span>
                <span className="about-text-highlight">our</span>
                <br/>
                <span className="about-text-highlight">success</span>
              </h1>
              
              <button className="about-button" onClick={() => navigate('/aboutus')}>
                <span className="about-learn-more-text">Learn More</span>
                <img src={arrowImg} alt="Arrow" />
              </button>
            </div>
            <div className="mini-nav-tabs">
              {tabsConfig.map((tab) => (
                <a
                  key={tab.name}
                  onClick={() => handleTabClick(tab.name)}
                  className={`tab-link-about ${activeTab === tab.name ? 'active' : ''}`}
                  href='#'
                >
                  {tab.label}
                </a>
              ))}
            </div>
            <AnimatePresence mode="wait"> {/* Fix applied here */}
              {tabsConfig.map((tab) => (
                activeTab === tab.name && (
                  <PageTransition key={tab.name}>
                    <motion.div
                      variants={wordingVariants}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                      transition={wordingVariants.transition}
                    >
                    </motion.div>
                  </PageTransition>
                )
              ))}
            </AnimatePresence>
          </motion.div>
          <motion.div className="about-image-container" variants={imageVariants} initial="initial" animate="animate" exit="exit">
            <img src={img2} className="about-image" alt="Profile" />
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default AboutPage;
