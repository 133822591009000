import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon
import { useNavigate } from 'react-router-dom';
// import { useSwipeable } from 'react-swipeable';
import './AboutUs.css';
import videoSrc from '../vid/AboutUs.mp4'; // Path to your video file
import exampleImage from '../img/Rectangle 15.jpg'; // Path to your image file
import bannerImage from '../img/Rectangle 20.png'; // Path to your banner image
import sideImage from '../img/Rectangle 26.png'; // Path to your new left-side image
import sliderImage1 from '../img/Our Clients-20241004T013933Z-001/Our Clients/The Empire Logo.png'; // Path to your slider images
import sliderImage2 from '../img/Our Clients-20241004T013933Z-001/Our Clients/Jabatan Ukur Logo.jpg'; // Path to your slider images
import sliderImage3 from '../img/Our Clients-20241004T013933Z-001/Our Clients/TCP Logo.png'; // Path to your slider images
import sliderImage4 from '../img/Our Clients-20241004T013933Z-001/Our Clients/Brunei-Darussalam-Government-Scholarships.jpg'; // Path to your slider images
import sliderImage5 from '../img/Our Clients-20241004T013933Z-001/Our Clients/Brunei LNG.jpg'; // Path to your slider images
import sliderImage6 from '../img/Our Clients-20241004T013933Z-001/Our Clients/PB Logo.png'; // Path to your slider images
import partnerImage1 from '../img/Our Partners-20241004T013942Z-001/Our Partners/Juruukur Logo-Jawi.png';
import partnerImage2 from '../img/Our Partners-20241004T013942Z-001/Our Partners/UTM Logo.png';
import partnerImage3 from '../img/Our Partners-20241004T013942Z-001/Our Partners/Map2u.png';
import partnerImage4 from '../img/Our Partners-20241004T013942Z-001/Our Partners/GMT Group Logo.png';
import arrowImage from '../img/Double Down.png'; // Path to your new arrow image
import arrowImageUp from '../img/Double Up.png'; // Path to your new arrow image
import arrowImg from '../img/arrow.png'; // Import your arrow image
import finalimg from '../img/Rectangle 42.png';
import logoImage from '../img/360Geoinfo_Logo_PNG.png';
import LogoImage from '../img/360Geoinfo_Logo_PNG.png';

const tabsConfig = [
  { name: 'about', label: 'About', color: '#627965' },
  { name: 'services', label: 'Services', color: '#D6B892' },
  { name: 'contact', label: 'Contact', color: '#3D3528' }
];

const AboutUs = () => {
  const navigate = useNavigate();
  const [view, setView] = useState('video');
  const [fade, setFade] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [sliderImage1, sliderImage2, sliderImage3, sliderImage4, sliderImage5, sliderImage6];
  const totalSlides = slides.length;
  const [activeTab, setActiveTab] = useState(tabsConfig[0].name);
  const [backgroundColor, setBackgroundColor] = useState(tabsConfig[0].color);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [path, setPath] = useState(null);
  const [clickEvent, setClickEvent] = useState(null);    
  const [currentIndex, setCurrentIndex] = useState(0);  // State to track the current slide

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? totalSlides - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === totalSlides - 1 ? 0 : prevIndex + 1));
  };
  
  useEffect(() => {
    // Update background color on tab change
    document.documentElement.style.setProperty('--background-color', backgroundColor);
  }, [backgroundColor]);

  const handleTabClick = (tab) => {
    const tabConfig = tabsConfig.find(t => t.name === tab);
    if (tabConfig) {
      setBackgroundColor(tabConfig.color);
    }
    setActiveTab(tab);
    navigate(`/${tab}`);
  };
  
  const handleTransition = () => {
    setFade(true);
    setTimeout(() => {
      // Cycle through views in a specific order
      switch(view) {
        case 'video':
          setView('aboutsection');
          break;
        case 'aboutsection':
          setView('goal&commit');
          break;
        case 'goal&commit':
          setView('key-to-success');
          break;
        case 'key-to-success':
          setView('our-client');
          break;
        case 'our-client':
          setView('imageTextButtonLayout');
          break;
        default:
          setView('video');
      }
      setFade(false);
    }, 1000); // Match the CSS transition duration
  };

  const handleReverseTransition = () => {
    setFade(true);
    setTimeout(() => {
      // Cycle through views in the reverse order
      switch(view) {
        case 'imageTextButtonLayout':
          setView('our-client');
          break;
        case 'our-client':
          setView('key-to-success');
          break;
        case 'key-to-success':
          setView('goal&commit');
          break;
        case 'goal&commit':
          setView('aboutsection');
          break;
        case 'aboutsection':
          setView('video');
          break;
        default:
          setView('imageTextButtonLayout');
      }
      setFade(false);
    }, 1000); // Match the CSS transition duration
  };
  

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
    }, 3000); // Adjust time based on your needs
    return () => clearInterval(interval);
  }, [totalSlides]);

  // const visibleSlides = [0, 1, 2, 3, 4, 5, 6];

  const handleDrawerButtonClick = (event) => {
    setClickEvent(event);
    setDrawerOpen(true);

    // Create ripple effect
    const ripple = document.createElement('span');
    const rect = event.currentTarget.getBoundingClientRect();
    const size = Math.max(rect.width, rect.height);
    const x = event.clientX - rect.left - size / 2;
    const y = event.clientY - rect.top - size / 2;

    ripple.style.width = ripple.style.height = `${size}px`;
    ripple.style.left = `${x}px`;
    ripple.style.top = `${y}px`;
    ripple.classList.add('ripple');

    event.currentTarget.appendChild(ripple);

    // Remove ripple effect after animation
    setTimeout(() => {
      ripple.remove();
    }, 600);
  };

  const handleExitButtonClick = () => {
    setDrawerOpen(false); // Close the drawer when exit button is clicked
  };

  const handleNavClick = (event, newPath, color) => {
    setClickEvent(event);
    setPath(newPath);
    setDrawerOpen(false); // Close the drawer after clicking a link
    setBackgroundColor(color); // Set background color based on the clicked link

    // Delay the navigation to ensure the circle transition completes
    setTimeout(() => {
      setClickEvent(null);
      setPath(null);
    }, 500); // Duration should match the CircleTransition animation duration
  };

  const boxVariants = {
    hidden: { opacity: 0, scale: 0.5 }, // Start small and invisible
    visible: { 
      opacity: 1, 
      scale: 1, 
      transition: { duration: 1.2, ease: 'easeOut' } 
    }, // Zoom to full size
  };
  
  const box2Variants = {
    hidden: { opacity: 0, scale: 0.5 }, // Start small and invisible
    visible: { 
      opacity: 1, 
      scale: 1, 
      transition: { duration: 1.2, ease: 'easeOut' } 
    }, // Zoom to full size
  };

  return (
    <div className="about-us-page">
      <div className={`page-container ${fade ? 'fade-out' : 'fade-in'}`}>
        <div className="main-content-about-us">
          <div className="content">
            {view === 'video' && (
              <>
                <div className="video-wrapper">
                  <video autoPlay muted loop className="background-video">
                    <source src={videoSrc} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <div className="content-overlay">
                    <h1 className="intro-text">Introducing</h1>
                    <img src={logoImage} alt="Logo" className="center-logo" />
                  </div>
                  <img 
                    src={arrowImage} 
                    alt="Arrow Button" 
                    className="arrow-image-button-video" 
                    onClick={handleTransition} 
                  />
                </div>
              </>
            )}
            {view === 'aboutsection' && (
              <>
                <div className="old-content-wrapper">
                  <div className="logo-navigation">
                    <Link to="/">
                      <img src={LogoImage} alt="YourLogo" className="logo-image" />
                    </Link>
                      <motion.button
                        className="other-drawer-button"
                        onClick={handleDrawerButtonClick}
                        whileTap={{ scale: 0.9 }} // Scale down on click
                        transition={{ duration: 0.2 }} // Duration of the scaling animation
                      >
                        <MenuIcon />
                      </motion.button>
                      <AnimatePresence>
                        {drawerOpen && (
                          <motion.div
                            className="other-full-screen-menu"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                          >
                            <button className="other-exit-button" onClick={handleExitButtonClick}>
                              <CloseIcon className="other-close-icon" /> {/* Use CloseIcon */}
                            </button>
                            <ul>
                              <li>
                                <Link to="/about" onClick={(e) => handleNavClick(e, '/about', '#627965')}>About Us</Link>
                              </li>
                              <li>
                                <Link to="/services" onClick={(e) => handleNavClick(e, '/services', '#D6B892')}>Our Services</Link>
                              </li>
                              <li>
                                <Link to="/contact" onClick={(e) => handleNavClick(e, '/contact', '#3D3528')}>Contact Us</Link>
                              </li>
                            </ul>
                          </motion.div>
                        )}
                      </AnimatePresence>
                  </div>
                  <div className="text-and-image">
                    <div className="text-content">
                      <h1 className="section-title">About Us</h1>
                      <p className="example-text">
                      360 GEOINFO was established in late 2023 
                      with the mission to provide innovative mapping and surveying solutions, 
                      leveraging advanced technologies to serve both public and private sectors in 
                      Brunei Darussalam during a time of rapid national development and economic growth. <br/>
                        
                      <br/>Our diverse team blends experienced professionals with dynamic young talent, 
                      bringing together expertise from fields such as Geography, Geology, Surveying, Full Stack Development, 
                      and Software Development. Together, we collaborate to deliver our four core services: 
                      Geospatial, Geographic Information Systems (GIS), Geoscience, and Geo-ICT.
                      </p>
                    </div>
                    <img src={exampleImage} alt="Example" className="example-image" />
                  </div>
                  <img 
                    src={arrowImageUp} 
                    alt="Arrow Button" 
                    className="arrow-image-button-video-opposite" 
                    onClick={handleReverseTransition} 
                  />
                  <img 
                    src={arrowImage} 
                    alt="Arrow Button" 
                    className="arrow-image-button-video" 
                    onClick={handleTransition} 
                  />
                </div>
              </>
            )}
            {view === 'goal&commit' && (
              <>
                <div className="full-page-section">
                  <div className="logo-navigation">
                    <Link to="/">
                      <img src={LogoImage} alt="YourLogo" className="logo-image" />
                    </Link>
                      <motion.button
                        className="other-drawer-button"
                        onClick={handleDrawerButtonClick}
                        whileTap={{ scale: 0.9 }} // Scale down on click
                        transition={{ duration: 0.2 }} // Duration of the scaling animation
                      >
                        <MenuIcon />
                      </motion.button>
                      <AnimatePresence>
                        {drawerOpen && (
                          <motion.div
                            className="other-full-screen-menu"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                          >
                            <button className="other-exit-button" onClick={handleExitButtonClick}>
                              <CloseIcon className="other-close-icon" /> {/* Use CloseIcon */}
                            </button>
                            <ul>
                              <li>
                                <Link to="/about" onClick={(e) => handleNavClick(e, '/about', '#627965')}>About Us</Link>
                              </li>
                              <li>
                                <Link to="/services" onClick={(e) => handleNavClick(e, '/services', '#D6B892')}>Our Services</Link>
                              </li>
                              <li>
                                <Link to="/contact" onClick={(e) => handleNavClick(e, '/contact', '#3D3528')}>Contact Us</Link>
                              </li>
                            </ul>
                          </motion.div>
                        )}
                      </AnimatePresence>
                  </div>
                  <div className="phone-view">
                    <div className="banner-wrapper">
                      <img src={bannerImage} alt="Banner" className="banner-image" />
                    </div>
                    <div className="layout-wrapper">
                      <div className="boxes-container">
                        <motion.div
                          className="box box1"
                          initial="hidden"
                          animate="visible"
                          variants={boxVariants}
                        >
                          <h1>Our Aim</h1>
                          <p>
                            360 GEOINFO Company aims to lead in the acquisition and
                            management of spatial information, offering comprehensive
                            Geographic Information System (GIS), Geospatial, Geo-ICT, and
                            Geosciences services within Negara Brunei Darussalam.
                          </p>
                        </motion.div>
            
                        <motion.div
                          className="box box2"
                          initial="hidden"
                          animate="visible"
                          variants={box2Variants}
                        >
                          <h1>Our Commitment</h1>
                          <p>
                            Our commitment aligns with the vision of His Majesty the Sultan
                            of Brunei, Wawasan 2035, as we pledge to deliver services that
                            consistently meet or exceed our clients' contractual
                            expectations.
                          </p>
                        </motion.div>
                      </div>
                    </div>
                  </div>
                  <img 
                    src={arrowImageUp} 
                    alt="Arrow Button" 
                    className="arrow-image-button-video-opposite" 
                    onClick={handleReverseTransition} 
                  />
                  <img 
                    src={arrowImage} 
                    alt="Arrow Button" 
                    className="arrow-image-button-video" 
                    onClick={handleTransition} 
                  />
                  </div>   
              </>
            )}
            {view === 'key-to-success' && (
              <>
                <div className="additional-content-section">
                  <div className="logo-navigation">
                    <Link to="/">
                      <img src={LogoImage} alt="YourLogo" className="logo-image" />
                    </Link>
                      <motion.button
                        className="other-drawer-button"
                        onClick={handleDrawerButtonClick}
                        whileTap={{ scale: 0.9 }} // Scale down on click
                        transition={{ duration: 0.2 }} // Duration of the scaling animation
                      >
                        <MenuIcon />
                      </motion.button>
                      <AnimatePresence>
                        {drawerOpen && (
                          <motion.div
                            className="other-full-screen-menu"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                          >
                            <button className="other-exit-button" onClick={handleExitButtonClick}>
                              <CloseIcon className="other-close-icon" /> {/* Use CloseIcon */}
                            </button>
                            <ul>
                              <li>
                                <Link to="/about" onClick={(e) => handleNavClick(e, '/about', '#627965')}>About Us</Link>
                              </li>
                              <li>
                                <Link to="/services" onClick={(e) => handleNavClick(e, '/services', '#D6B892')}>Our Services</Link>
                              </li>
                              <li>
                                <Link to="/contact" onClick={(e) => handleNavClick(e, '/contact', '#3D3528')}>Contact Us</Link>
                              </li>
                            </ul>
                          </motion.div>
                        )}
                      </AnimatePresence>
                  </div>
                    <div className="new-content-wrapper">
                      <div className="banner-content-wrapper">
                        <img src={sideImage} alt="Side Image" className="side-image" />
                      </div>
                      <div className="new-content-text">
                        <div className="content-block">
                          <h2>The Key to Success</h2>
                          <p>
                            360 GEOINFO Company is dedicated to continually enhancing our Health, Safety, 
                            and Environment (HSE) system as the health and safety of our employees, contractors, 
                            and relevant third parties is paramount in our operations.
                          </p>
                          <p>
                            We are fully committed to supporting Wawasan 2035 by optimizing local workforce 
                            utilization and promoting the use of local goods and services in all our operations.
                          </p>
                          <p>
                            Furthermore, we are dedicated to environmentally responsible practices to safeguard our 
                            natural surroundings in support of towards achieving the Sustainable Development Goals (SDGs).
                          </p>
                        </div>
                      </div>
                    </div>
                  <img 
                    src={arrowImageUp} 
                    alt="Arrow Button" 
                    className="arrow-image-button-video-opposite" 
                    onClick={handleReverseTransition} 
                  />
                  <img 
                    src={arrowImage} 
                    alt="Arrow Button" 
                    className="arrow-image-button-video" 
                    onClick={handleTransition} 
                  />
                  </div>
              </>
            )}
            {view === 'our-client' && (
            <>
              <div className="slider-page-layout">
                  <div className="logo-navigation">
                    <Link to="/">
                      <img src={LogoImage} alt="YourLogo" className="logo-image" />
                    </Link>
                      <motion.button
                        className="other-drawer-button"
                        onClick={handleDrawerButtonClick}
                        whileTap={{ scale: 0.9 }} // Scale down on click
                        transition={{ duration: 0.2 }} // Duration of the scaling animation
                      >
                        <MenuIcon />
                      </motion.button>
                      <AnimatePresence>
                        {drawerOpen && (
                          <motion.div
                            className="other-full-screen-menu"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                          >
                            <button className="other-exit-button" onClick={handleExitButtonClick}>
                              <CloseIcon className="other-close-icon" /> {/* Use CloseIcon */}
                            </button>
                            <ul>
                              <li>
                                <Link to="/about" onClick={(e) => handleNavClick(e, '/about', '#627965')}>About Us</Link>
                              </li>
                              <li>
                                <Link to="/services" onClick={(e) => handleNavClick(e, '/services', '#D6B892')}>Our Services</Link>
                              </li>
                              <li>
                                <Link to="/contact" onClick={(e) => handleNavClick(e, '/contact', '#3D3528')}>Contact Us</Link>
                              </li>
                            </ul>
                          </motion.div>
                        )}
                      </AnimatePresence>
                  </div>
                  <div className="our-client-container">
                    {/* Title Section - Heading at the Top Middle */}
                    <div className="title-client">
                      <h2>Our Clients</h2>
                    </div>

                    {/* Carousel Section - Centered in the Middle */}
                    <div className="carousel-banner">
                      {/* Left Arrow */}
                      <button className="carousel-arrow left-arrow" onClick={handlePrev}>
                        &#9664;
                      </button>

                      <div className="carousel-wrapper">
                        <div className="carousel-container">
                          <div
                            className="carousel"
                            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                          >
                            {slides.map((slide, index) => (
                              <div className="carousel-card" key={index}>
                                <img src={slide} alt={`Slide ${index}`} className="carousel-image" />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      {/* Right Arrow */}
                      <button className="carousel-arrow right-arrow" onClick={handleNext}>
                        &#9654;
                      </button>
                    </div>

                    {/* Partners Section - Bottom Middle */}
                    <div className="partners-section">
                      {/* Title Section */}
                      <div className="partners-title-box">
                        <h3>Our Partners</h3>
                      </div>

                      {/* Vertical Line */}
                      <div className="vertical-line"></div>

                      {/* Partners Images Section */}
                      <div className="partners-images">
                        <div className="partner-img">
                          <img src={partnerImage1} alt="Partner 1" className="circular-image" />
                        </div>
                        <div className="partner-img">
                          <img src={partnerImage2} alt="Partner 2" className="circular-image" />
                        </div>
                        <div className="partner-img">
                          <img src={partnerImage3} alt="Partner 3" className="circular-image" />
                        </div>
                        <div className="partner-img">
                          <img src={partnerImage4} alt="Partner 4" className="circular-image" />
                        </div>
                      </div>
                    </div>
                  </div>
                    {/* Arrow Button */}
                    <img 
                      src={arrowImageUp} 
                      alt="Arrow Button" 
                      className="arrow-image-button-video-opposite" 
                      onClick={handleReverseTransition} 
                    />
                    <img
                      src={arrowImage}
                      alt="Arrow Button"
                      className="arrow-image-button-video"
                      onClick={handleTransition}
                    />
              </div>       
            </>          
            )}
            {view === 'imageTextButtonLayout' && (
              <>
                <div className="image-text-button-layout">
                  <div className="logo-navigation">
                    <Link to="/">
                      <img src={LogoImage} alt="YourLogo" className="logo-image" />
                    </Link>
                      <motion.button
                        className="other-drawer-button"
                        onClick={handleDrawerButtonClick}
                        whileTap={{ scale: 0.9 }} // Scale down on click
                        transition={{ duration: 0.2 }} // Duration of the scaling animation
                      >
                        <MenuIcon />
                      </motion.button>
                      <AnimatePresence>
                        {drawerOpen && (
                          <motion.div
                            className="other-full-screen-menu"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                          >
                            <button className="other-exit-button" onClick={handleExitButtonClick}>
                              <CloseIcon className="other-close-icon" /> {/* Use CloseIcon */}
                            </button>
                            <ul>
                              <li>
                                <Link to="/about" onClick={(e) => handleNavClick(e, '/about', '#627965')}>About Us</Link>
                              </li>
                              <li>
                                <Link to="/services" onClick={(e) => handleNavClick(e, '/services', '#D6B892')}>Our Services</Link>
                              </li>
                              <li>
                                <Link to="/contact" onClick={(e) => handleNavClick(e, '/contact', '#3D3528')}>Contact Us</Link>
                              </li>
                            </ul>
                          </motion.div>
                        )}
                      </AnimatePresence>
                  </div>
                  <div className="phone-view-layout">
                      <div className="text-section">
                            <p className="subheading-about-mini">02. SERVICES</p>
                          <h1 className="service-title">
                            <span className="text-white-about-mini">Precision</span>
                            <br />
                            <span className="text-white-about-mini"> through </span>
                            <br />
                            <span className="text-highlight-about-mini">geoexcellence</span>
                          </h1>
                      </div>
                          <img src={finalimg} alt="Centered Content" className="center-image" />
                      <div className="button-section">
                        <button className="mini-about-button" onClick={() => navigate('/servicecontent')}>
                            <span className="learn-more-text-about">Learn More</span>
                            <img className="learn-more-arrow-serivce" src={arrowImg} alt="Arrow" />
                        </button>
                      </div>
                  </div>
                </div>
                <img 
                    src={arrowImageUp} 
                    alt="Arrow Button" 
                    className="arrow-image-button-video-opposite" 
                    onClick={handleReverseTransition} 
                  />
              </>
            )}
          </div>
        </div> 
        <div className="tabs">
            {tabsConfig.map((tab) => (
              <div
                key={tab.name}
                className={`tab ${activeTab === tab.name ? 'active' : ''}`}
                onClick={() => handleTabClick(tab.name)}
              >
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
