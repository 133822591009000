import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Slider from 'react-slick';
import gnss from "../../img/20200908_163530 1.png";
import seabed from "../../img/geophysical_survey 1.png";
import farid from "../../img/farid.png";
import pickaxe from "../../img/pickaxe.png";
import sliderImg1 from "../../img/GeoScience/Geological Surveys and Mapping.png";  // Separate images for slider
import sliderImg2 from "../../img/GeoScience/Natural Hazard Analysis.png"; // Add more images for the slider
import sliderImg3 from "../../img/GeoScience/Climate and Weather Studies.png";  // Separate images for slider
import sliderImg4 from "../../img/GeoScience/Geophysical Survey.png"; // Add more images for the slider
import sliderImg5 from "../../img/GeoScience/Educational Outreach and Public Engagement.png";  // Separate images for slider
import sliderImg6 from "../../img/GeoScience/Consulting and Advisory Services.png"; // Add more images for the slider
import sliderImg7 from "../../img/GeoScience/Consulting and Advisory Services.png"; // Add more images for the slider
import LogoImage from '../../img/360Geoinfo_Logo_PNG.png';
import './GeoScience.css';

const tabsConfig = [
  { name: 'about', label: 'About', color: '#627965' },
  { name: 'services', label: 'Services', color: '#D6B892' },
  { name: 'contact', label: 'Contact', color: '#3D3528' }
];

const GeoSciencePage = () => {
    const [clickEvent, setClickEvent] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState(tabsConfig[0].color);
    const [path, setPath] = useState(null);
    const largeImageRef = useRef(null);
    const smallImagesRef = useRef(null);
    const textContentRef = useRef(null);
    const titleRef = useRef(null);

    const slideContentsScience= [
        "We specialize in conducting detailed geological surveys and mapping, utilizing advanced softwares to analyze subsurface geology, stratigraphy, and structural features for applications in resource exploration and land-use planning.",
        "Employing advanced geophysical techniques, our surveys accurately identify and map subsurface utilities, mitigating risks and supporting informed infrastructure development.",
        "Employing techniques such as seismic reflection, electromagnetic, and gravimetric surveys, and utilizing specialized softwares, we provide in-depth analysis of subsurface properties for resource identification, geotechnical investigations, and environmental assessments.",
        "Our natural hazard analysis integrates GIS-based modeling and probabilistic assessment methods to evaluate risks from seismic events, floods, landslides, and other hazards, supporting disaster risk reduction and resilient infrastructure design.",
        "Our educational outreach programs are designed to enhance public understanding of geoscience through interactive GIS platforms, workshops, and digital resources, fostering informed community involvement in environmental and resource management.",
        "We offer expert consulting and advisory services, drawing on our deep expertise in geoscience and utilizing industry-standard software to deliver tailored solutions in areas such as resource exploration, environmental impact assessment, and geotechnical engineering.",
        "Using state-of-the-art seismic sensors, we provide real-time noise monitoring to deliver precise data on subsurface activity, enhancing site safety and project planning."
    ];

    const slideTitlesScience = [
        "Geological Surveys and Mapping",
        "Underground and Utility Detection Survey",
        "Geophysical Survey",
        "Natural Hazard Analysis",
        "Educational Outreach and Public Engagement",
        "Consulting and Advisory Services",
        "Seismic Noise Monitoring"
    ];
    
    const sliderImages = [sliderImg1, sliderImg2, sliderImg3, sliderImg4, sliderImg5, sliderImg6, sliderImg7]; // Images for the slider

    const [activeSlide, setActiveSlide] = useState(0);

    useEffect(() => {
        const options = {
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animate');
                }
            });
        }, options);

        observer.observe(largeImageRef.current);
        observer.observe(smallImagesRef.current);
        observer.observe(textContentRef.current);
        observer.observe(titleRef.current);

        return () => observer.disconnect();
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        afterChange: (current) => {
            setActiveSlide(current);
        },
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    }; 

    const handleDrawerButtonClick = (event) => {
      setClickEvent(event);
      setDrawerOpen(true);
  
      // Create ripple effect
      const ripple = document.createElement('span');
      const rect = event.currentTarget.getBoundingClientRect();
      const size = Math.max(rect.width, rect.height);
      const x = event.clientX - rect.left - size / 2;
      const y = event.clientY - rect.top - size / 2;
  
      ripple.style.width = ripple.style.height = `${size}px`;
      ripple.style.left = `${x}px`;
      ripple.style.top = `${y}px`;
      ripple.classList.add('ripple');
  
      event.currentTarget.appendChild(ripple);
  
      // Remove ripple effect after animation
      setTimeout(() => {
        ripple.remove();
      }, 600);
    };
  
    const handleExitButtonClick = () => {
      setDrawerOpen(false); // Close the drawer when exit button is clicked
    };
  
    const handleNavClick = (event, newPath, color) => {
      setClickEvent(event);
      setPath(newPath);
      setDrawerOpen(false); // Close the drawer after clicking a link
      setBackgroundColor(color); // Set background color based on the clicked link
  
      // Delay the navigation to ensure the circle transition completes
      setTimeout(() => {
        setClickEvent(null);
        setPath(null);
      }, 500); // Duration should match the CircleTransition animation duration
    }; 

    return (
        <div className="geoscience-container">
            <div className="logo-navigation">
                <Link to="/">
                    <img src={LogoImage} alt="YourLogo" className="logo-image" />
                </Link>
                    <div className="navbar-text">
                        <Link to="/geospatial" className="navbar-link">Geospatial</Link> .  
                        <Link to="/gis" className="navbar-link"> GIS</Link> .
                        <Link to="/geoscience" className="navbar-link">Geoscience</Link> .  
                        <Link to="/geoict" className="navbar-link"> Geo-ICT</Link>
                    </div>
                <motion.button
                className="other-drawer-button-GScience-content"
                onClick={handleDrawerButtonClick}
                whileTap={{ scale: 0.9 }} // Scale down on click
                transition={{ duration: 0.2 }} // Duration of the scaling animation
                >
                <MenuIcon />
                </motion.button>
                <AnimatePresence>
                {drawerOpen && (
                    <motion.div
                    className="other-full-screen-menu-GScience-content"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    >
                    <button className="other-exit-button-GScience-content" onClick={handleExitButtonClick}>
                        <CloseIcon className="other-close-icon-GScience-content" /> {/* Use CloseIcon */}
                    </button>
                    <ul>
                        <li>
                        <Link to="/about" onClick={(e) => handleNavClick(e, '/about', '#627965')}>About Us</Link>
                        </li>
                        <li>
                        <Link to="/services" onClick={(e) => handleNavClick(e, '/services', '#D6B892')}>Our Services</Link>
                        </li>
                        <li>
                        <Link to="/contact" onClick={(e) => handleNavClick(e, '/contact', '#3D3528')}>Contact Us</Link>
                        </li>
                    </ul>
                    </motion.div>
                )}
                </AnimatePresence>
                <div className="other-navbar-right-GScience-content">
                    <Link to="/contactcontent" className="other-contact-link-GScience-content">
                    <div className="other-contact-box-GScience-content">
                        <p className="other-contact-text-top-right-GScience-content">Contact Us</p>
                        <div className="other-logo-container-GScience-content">
                        <div className="other-logo-background-GScience-content">
                            <a href="https://wa.me/12345678901" target="_blank" rel="noopener noreferrer">
                            <WhatsAppIcon className="other-whatsapp-icon-GScience-content" />
                            </a>
                        </div>
                        </div>
                    </div>
                    </Link>
                </div>
            </div>
            <div className="geoscience-phone-view">
                <div className="geoscience-image-row">
                    {/* Large Image */}
                    <div className="geoscience-image-large" ref={largeImageRef}>
                        <img src={farid} alt="Large GeoScience" />
                    </div>

                    {/* Small Images and Title */}
                    <div className="geoscience-small-images-container" ref={smallImagesRef}>
                        <div className="geoscience-small-images">
                            <img src={gnss} alt="Small GeoScience 1" className="geoscience-image-small" />
                            <img src={seabed} alt="Small GeoScience 1" className="geoscience-image-small" />
                            <img src={pickaxe} alt="Small GeoScience 1" className="geoscience-image-small" />
                        </div>
                    </div>
                </div>

                {/* Carousel */}
                <div className="geoscience-carousel-container">
                        {/* Title Below Small Images */}
                        <div className="geoscience-title" ref={titleRef}>
                            <h2>Geoscience</h2>
                        </div>
                    <Slider {...settings}>
                        {sliderImages.map((image, index) => (
                            <div key={index} className={`geoscience-carousel-card ${activeSlide === index ? 'active' : ''}`}>
                                <div className={`geoscience-carousel-card-content ${activeSlide === index ? 'active' : ''}`}>
                                    <div className="geoscience-carousel-card-image">
                                        <div className="geoscience-carousel-card-text">
                                            <div style={{ textAlign: 'center', color: 'white', fontSize: 30, fontFamily: 'Avenir Next Cyr', fontWeight: '450', wordWrap: 'break-word' }}>
                                                {slideTitlesScience[index]}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>

                <div className="geoscience-text-content" ref={textContentRef}>
                    <p>{slideContentsScience[activeSlide]}</p>
                </div>
            </div>
        </div>
    );
};

export default GeoSciencePage;
